import * as React from "react"
import zparse from 'helpers/zparse';

const Alignment = ({title, content, chart}) => {
    return (
        <section id="rating-alignment">
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12">
                        <h2>{title}</h2>
                        {zparse(content)}
                        {chart && <img style={{ paddingBottom: "81px" }} src={chart} alt="" /> }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Alignment;